export const LOGIT_INTERCEPTS = {
    1: -0.561507996758981,
    2: -2.3910236468475725,
    3: -3.416980693892728,
    4: -1.4734433731836558,
    5: -1.8771874073993107,
    6: -2.4943833597584772,
    7: -2.3445122654705277,
    8: -2.9042902401283914,
    9: -3.9485437824322047,
    10: -3.498727816135122,
    11: -3.087654314633845,
    12: -2.291711493274579,
    13: -1.625115775700989,
    14: -2.5828128977143083,
    15: 0.0,
    16: -1.2799651039304363,
    17: -1.11410273291592,
    18: -2.2358429940580162,
    19: -0.5660475913039064,
    20: -1.6092478258333254,
};
export const LOGIT_PFA = {
    1: 0.14966164878495522,
    2: 0.25218365939662707,
    3: 0.231708947364229,
    4: 0.18309532732557302,
    5: 0.2637382656551153,
    6: 0.2939181513044629,
    7: 0.24626742824875847,
    8: 0.2785709636717368,
    9: 0.369043715829241,
    10: 0.2039691775075483,
    11: 0.1542341295554925,
    12: 0.2129167492694177,
    13: 0.21553064690143947,
    14: 0.2507154715089394,
    15: 0.28185721605456987,
    16: 0.1908251542533385,
    17: 0.16004078254116946,
    18: 0.18253975460237118,
    19: 0.26348633402148575,
    20: 0.2785095327905037,
};
export const LOGIT_NFA = {
    1: 0.450002918215879,
    2: 0.31384735223908067,
    3: 0.2895319445061863,
    4: 0.5295613118903879,
    5: 0.3616112044219725,
    6: 0.38521977738615076,
    7: 0.26855689011954337,
    8: 0.2910106804937566,
    9: 0.21449728044013544,
    10: 0.3813333278865131,
    11: 0.425265454657212,
    12: 0.46142380882073475,
    13: 0.48820196139150945,
    14: 0.3349490624496898,
    15: 0.5058001212185339,
    16: 0.4207300076951898,
    17: 0.46417828981719894,
    18: 0.4648741996981874,
    19: 0.4202921965035575,
    20: 0.36600366496440884,
};
export const LOGIT_IS_POS2 = {
    1: 0.013423890790807021,
    2: 0.0852270270931029,
    3: 0.2145811528925629,
    4: 0.2912796460853972,
    5: 0.25078886087835117,
    6: 0.2521172298470426,
    7: 0.3482867593998055,
    8: -0.04191014401326856,
    9: 0.21583796388859638,
    10: 0.4253354900969586,
    11: 0.5461745515659613,
    12: 0.3064628988983479,
    13: 0.34065856104597575,
    14: 0.24896242677221336,
    15: 0.11297589526516576,
    16: 0.0427359934315568,
    17: 0.06903536354508383,
    18: 0.36470994162545545,
    19: 0.17827717425147052,
    20: 0.0962165332911746,
};
export const LOGIT_IS_POS3 = {
    1: 0.3040646942121632,
    2: 0.45521491864762514,
    3: 0.5693789998826049,
    4: 0.5969939376471685,
    5: 0.5616957608086147,
    6: 0.3789250141142684,
    7: 0.5948864983485316,
    8: 0.1929495459046293,
    9: 0.5424587211097297,
    10: 0.8208294134027709,
    11: 0.60998275971722,
    12: 0.5518155084755564,
    13: 0.5255700621548771,
    14: 0.4656611978918814,
    15: 0.4015016821287404,
    16: 0.3042798478122242,
    17: 0.23810121624242248,
    18: 0.6537357854081105,
    19: 0.5153971861300151,
    20: 0.5535387015892929,
};
export const LOGIT_IS_POS4 = {
    1: 0.4641903712867179,
    2: 0.6843539952819391,
    3: 0.7882657683795911,
    4: 0.8777707522367333,
    5: 0.8030192048429396,
    6: 0.6738646939813979,
    7: 0.8333887099400131,
    8: 0.5971917747589108,
    9: 1.0108355973770864,
    10: 1.0389896172090665,
    11: 1.0757480111626188,
    12: 0.9764798776353428,
    13: 0.9138759265691934,
    14: 0.6991041993044049,
    15: 0.5239604048358979,
    16: 0.6796416378701087,
    17: 0.5352350771384046,
    18: 0.9449813601080745,
    19: 0.7402187119099112,
    20: 0.845873116778588,
};
